import 'fslightbox';
import "@fontsource/dm-mono/400.css";
import "@fontsource/dm-mono/400-italic.css";

document.documentElement.style.setProperty(
    '--scrollbar-width',
    (window.innerWidth - document.documentElement.offsetWidth) + 'px',
);

window.addEventListener('mousemove', function (event) {
    const mouse = document.getElementById('mouse');
    const offset = mouse.getBoundingClientRect().width / 2;

    mouse.style.top = `${(event.clientY - offset).toString()}px`;
    mouse.style.left = `${(event.clientX - offset).toString()}px`;
});

document.querySelectorAll('.view-case-study-trigger').forEach((caseStudy) => {
    caseStudy.addEventListener('mouseover', (event) => {
        document.getElementById('mouse').classList.add('view-case-study');
    });

    caseStudy.addEventListener('mouseout', (event) => {
        document.getElementById('mouse').classList.remove('view-case-study');
    })
});

document.querySelectorAll('.copy-email').forEach((el) => el.addEventListener('click', function (event) {
    navigator.clipboard.writeText('alexa@rathernice.com');

    document.querySelectorAll('.copy-email').forEach((el) => el.classList.add('copied'));

    window.setTimeout(() => document.querySelectorAll('.copy-email').forEach((el) => el.classList.remove('copied')), 2000);
}));
